  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/1-6-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li>Assistance réception bien immobilier ou travaux</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>La réception de votre bien ou de vos travaux. Une phase complexe pour le non-sachant. </h1>

<p>La phase de réception de votre ouvrage constitue l'ultime étape de votre chantier. </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Assistance réception bien immobilier ou travaux" />
</div>

<p>Celle-ci met un terme à un chantier et au contrat souscrit avec le constructeur. Elle ouvre le droit des garanties protégeant le futur Maître d’ouvrage.</p>

<p>Lors de la réception d’un ouvrage, vous pouvez alors établir une liste des réserves. </p>
<p>Vous avez alors un délai de 10 jours, suite à la visite de réception de chantier, pour prévenir le constructeur ou le Maître d’Oeuvre de malfaçons ou défauts constructifs.</p>

<p>L’émission d’un procès-verbal comportant des réserves, permet au Maître d’Ouvrage d’indiquer son insatisfaction au constructeur. </p>
<p>Ce dernier dispose alors de 60 jours pour lever les réserves qui lui sont notifiées. </p>

<h1>Pourquoi être accompagné par un Expert lors de la réception de travaux ? </h1>

<p>Les experts du cabinet RV Expertises vous accompagnent afin de vous assurer une réception d’ouvrage conforme aux obligations contractuelles.</p>

<p>Nos experts vérifient lors d’une visite technique la bonne réalisation et la conformité des ouvrages réalisés, conformément à la notice descriptive du contrat.</p>

<p>Ils décèlent d’éventuelles malfaçons et les non conformités. Nos experts s'assurent que les ouvrages soient conformes aux plans d'exécution et que les matériaux engagés respectent la notice descriptive.</p>

<p>Toutes les malfaçons, désordres rendant impropres à destination l’ouvrage sont compilés dans un rapport d’expertise. </p>

<p>En cas de conflit, nous endossons un rôle de médiateur avec le constructeur afin de vous assurer que les engagements contractuels soient respectés.</p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Nous contacter</a>
    </div>
</div>
                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/assistance-reception-immobilier/' className='active'>Assistance réception bien immobilier ou travaux</a></li><li><a href='/expertise-construction/assistance-reception-immobilier/ccmi/' >Réception CCMI</a></li><li><a href='/expertise-construction/assistance-reception-immobilier/vefa/' >Livraison VEFA</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details